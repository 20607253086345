<template>
    <div>
        <div class="page-header justify-content-start">
            <lable class="page-title">Member</lable>
            <ul class="breadcrumbs">
                <li>Raj Sinha > Pending Task (04)</li>
            </ul>
        </div>
        <section class="tasks-section">
            <div class="tasks-header">
                <div class="task-filters-left">
                    <ul>
                        <li v-on:click="isActive='All'" v-bind:class="{ active: isActive=='All' }">All</li>
                        <li v-on:click="isActive='High'" v-bind:class="{ active: isActive=='High' }" >High</li>
                        <li v-on:click="isActive='Medium'" v-bind:class="{ active: isActive=='Medium' }" >Medium</li>
                        <li v-on:click="isActive='Low'" v-bind:class="{ active: isActive=='Low' }" >Low</li>
                    </ul>
                </div>
                <div class="task-filters-right">
                    <ul>
                        <li>
                            <label>CATEGORY</label>
                            <multiselect
                                v-model="categorySelected" 
                                :show-labels="false" 
                                :options="categoryOptions">
                            </multiselect>
                        </li>
                        <li>
                            <label>SUB - CATEGORY</label> 
                            <multiselect
                                v-model="subCategorySelected" 
                                :show-labels="false" 
                                :options="subCategoryOptions">
                            </multiselect>
                        </li>
                        <li>
                            <label>TIME RANGE</label> 
                            <div class="date_range">
                                <dateRange></dateRange>                                 
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="task-wrap">
                <div class="task-list high">
                    <ul>
                        <li class="category">
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Kanha Budget 2020 Review - 2nd Version Approval</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>Task OWNER</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>Task ASSIGNED BY</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>Created on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>Assigned on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         <li class="priority">
                            <div class="task-item">
                                <label>PRIORITY</label>
                                <div class="task-content"><p>High</p></div>
                            </div>
                            <div class="task-item">
                                <label>ATTACHMENT</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small v-b-modal.attachments>1</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="task-actions">
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"><router-link to="/member-details">TAKE ACTION</router-link></div>
                            </div>
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"><a class="activity" href="#">Activity Log</a></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-list medium">
                    <ul>
                        <li class="category">
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Kanha Budget 2020 Review - 2nd Version Approval</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>Task OWNER</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>Task ASSIGNED BY</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>Created on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>Assigned on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         <li class="priority">
                            <div class="task-item">
                                <label>PRIORITY</label>
                                <div class="task-content"><p>Medium</p></div>
                            </div>
                            <div class="task-item">
                                <label>ATTACHMENT</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small v-b-modal.attachments>1</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="task-actions">
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"><router-link to="/member-details">TAKE ACTION</router-link></div>
                            </div>
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"><a class="activity" href="#">Activity Log</a></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-list low">
                    <ul>
                        <li class="category">
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Kanha Budget 2020 Review - 2nd Version Approval</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>Task OWNER</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>Task ASSIGNED BY</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>Created on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>Assigned on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         <li class="priority">
                            <div class="task-item">
                                <label>PRIORITY</label>
                                <div class="task-content"><p>Low</p></div>
                            </div>
                            <div class="task-item">
                                <label>ATTACHMENT</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small v-b-modal.attachments>1</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="task-actions">
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"><router-link to="/member-details">TAKE ACTION</router-link></div>
                            </div>
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"><a class="activity" href="#">Activity Log</a></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-list high">
                    <ul>
                        <li class="category">
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Kanha Budget 2020 Review - 2nd Version Approval</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>Task OWNER</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>Task ASSIGNED BY</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>Created on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>Assigned on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                        <li class="priority">
                        <div class="task-item">
                            <label>PRIORITY</label>
                            <div class="task-content"><p>High</p></div>
                        </div>
                        <div class="task-item">
                            <label>ATTACHMENT</label>
                            <div class="task-content">
                                <div class="attachments">
                                    <small v-b-modal.attachments>1</small>
                                </div>
                            </div>
                        </div>
                        </li>
                        <li class="task-actions">
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"><router-link to="/member-details">TAKE ACTION</router-link></div>
                            </div>
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"><a class="activity" href="#">Activity Log</a></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <b-modal ref="my-modal" id="attachments" centered hide-header hide-footer>
            <span class="close"  @click="hideModal"><span aria-hidden="true">&times;</span></span>
             <div class="modal-table">
                <table>
                    <thead>
                        <tr>
                            <td>Document Name</td>
                            <td>Document Type</td>
                            <td>ACTION</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="d-flex"><b-form-checkbox>Plantation Draft</b-form-checkbox></div>
                            </td>
                            <td><span>DOC</span></td>
                            <td><a href="#">DOWNLOAD</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex"><b-form-checkbox>Budget plan</b-form-checkbox></div>
                            </td>
                            <td><span>XSL</span></td>
                            <td><a href="#">DOWNLOAD</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex"><b-form-checkbox>Team Member list</b-form-checkbox></div>
                            </td>
                            <td><span>PDF</span></td>
                            <td><a href="#">DOWNLOAD</a></td>
                        </tr>
                    </tbody>
                </table>
             </div>
        </b-modal>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect-inv";
import dateRange from '@/components/dateRange.vue'
  export default {
    components: {
        Multiselect,
        dateRange
    },
    data() {
      return {
        isActive: 'All',
        categorySelected: 'All',
        subCategorySelected: 'All',
        TimeSelected: 'All',
        filteroptions: [
          { value: null, text: 'All' },
          { value: 'a', text: 'Option 1' },
          { value: 'b', text: 'Option 2' },
          { value: 'c', text: 'Option 3' },
          { value: 'd', text: 'Option 4',}
        ],
        //options: ['list', 'of', 'options'],
        categoryOptions: ['All  ', 'Category 1', 'Category 2', 'Category 3'],
        subCategoryOptions: ['All  ', 'Sub Category 1', 'Sub Category 2', 'Sub Category 3'],
        TimeOptions: ['All ', 'Time 1', 'Time 2', 'Time 3'],

      }
    },
     methods: {
      showModal() {
        this.$refs['my-modal'].show() 
      },
      hideModal() {
        this.$refs['my-modal'].hide() 
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn') 
      },
     




      
    },
  }
</script>